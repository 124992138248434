import React from 'react'

import Cart from '../components/Cart'

const SecondPage = () => (
  <>
    <h1>Cart</h1>
    <Cart />
  </>
)

export default SecondPage
